<template>
	<div id="page9" ref="page9">
		<div class="layout">
			<div class="gauche">
				<div class="logo">
					<img v-if="custom_logo" :src="custom_logo.url" />
					<img v-else src="../../assets/logovertical.png" />
				</div>
				<div class="infos">
					<div class="box" @blur="handleChange" v-if="$store.state.offre_active.page2_one"
						contenteditable="true" data-field="page2_one"
						v-html="$store.state.offre_active.page2_one"></div>
					<div class="box" @blur="handleChange" v-else contenteditable="true"
						data-field="page2_one">
						<h2>{{ $store.state.textes.page2.client }}</h2>
						<h3>{{ $store.state.offre_active.client.nom }}</h3>
						<h3>{{ $store.state.offre_active.client.contact_nom }}</h3>
						<div>
							<div v-html="$store.state.offre_active.client.adresse"></div>
							<div>{{ $store.state.offre_active.client.cp }} {{
								$store.state.offre_active.client.ville }}</div>
							Tel. {{ $store.state.offre_active.client.contact_telephone }}
							<span v-if="$store.state.offre_active.client.contact_mobile"><br />Mob. {{
								$store.state.offre_active.client.contact_mobile }}</span>
							<br />
							Email. {{ $store.state.offre_active.client.contact_email }}
						</div>
					</div>
				</div>
			</div>
			<div class="droite">
				<h1>
					<span>{{ $store.state.textes.page9.configuration }}</span>
				</h1>
				<hr />
				<div class="letter">
					<div class="date" v-if="$store.state.offre_active.langue == 'fr'">
						Le {{ $store.state.offre_active.date_modifiee | moment("D/MM/Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'en'">
						{{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'us'">
						{{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'ro'">
						{{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'de'">
						{{ $store.state.offre_active.date_modifiee | moment("D/MM/Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'pl'">
						{{ $store.state.offre_active.date_modifiee | moment("D/MM/Y") }}
					</div>

					<div class="dear" @blur="handleChange" v-if="$store.state.offre_active.page9_dear"
						contenteditable="true" data-field="page9_dear"
						v-html="$store.state.offre_active.page9_dear"></div>
					<div class="dear" @blur="handleChange" v-else contenteditable="true"
						data-field="page9_dear">
						{{ $store.state.textes.page9.dear }} {{ $store.state.offre_active.client.contact_nom
						}}
					</div>

					<div class="intro" @blur="handleChange"
						v-if="$store.state.offre_active.page9_intro && $store.state.offre_active.page9_intro != ''"
						contenteditable="true" data-field="page9_intro"
						v-html="$store.state.offre_active.page9_intro"></div>
					<div class="intro" @blur="handleChange" contenteditable="true" data-field="page9_intro">
						{{ $store.state.textes.page9.thanks }}
					</div>

					<div class="lamachine" @blur="handleChange"
						v-if="$store.state.offre_active.page2_intitule && $store.state.offre_active.page2_intitule != '-'"
						contenteditable="true" data-field="page2_intitule"
						v-html="$store.state.offre_active.page2_intitule"></div>
					<div class="lamachine" @blur="handleChange" v-else contenteditable="true"
						data-field="page2_intitule">
						<span v-if="$store.state.offre_active.langue == 'fr'">{{
							$store.state.offre_active.machine.display_name_fr }}</span>
						<span v-if="$store.state.offre_active.langue == 'en'">{{
							$store.state.offre_active.machine.display_name_en }}</span>
						<span v-if="$store.state.offre_active.langue == 'us'">{{
							$store.state.offre_active.machine.display_name_us }}</span>
						<span v-if="$store.state.offre_active.langue == 'ro'">{{
						$store.state.offre_active.machine.display_name_ro }}</span>
						<span v-if="$store.state.offre_active.langue == 'de'">{{
							$store.state.offre_active.machine.display_name_de }}</span>
						<span v-if="$store.state.offre_active.langue == 'pl'">{{
							$store.state.offre_active.machine.display_name_pl }}</span>
						<br />TYPE {{ $store.state.offre_active.machine.name }}
					</div>

					<div class="conditions" @blur="handleChange"
						v-if="$store.state.offre_active.page9_conditions" contenteditable="true"
						data-field="page9_conditions" v-html="$store.state.offre_active.page9_conditions">
					</div>
					<p class="conditions" @blur="handleChange" v-else data-field="page9_conditions"
						contenteditable="true">
						{{ $store.state.textes.page9.conditions }}
					</p>

					<ul class="recap">
						<li>
							<label>{{ $store.state.offre_active.machine.display_name }} TYPE {{
								$store.state.offre_active.machine.name }}
							</label>
							<span class="wrapper">
								<span class="hide_price masked" @click="toggleVisibility">Hide price</span>
								<money-format
									:value="$store.state.offre_active.machine.prix_distrib * taux_de_change"
									:currency-code="$store.state.offre_active.devise"
									:subunits-value="false" :hide-subunits="false"
									:locale="$store.state.offre_active.langue">
								</money-format>
							</span>
							<!-- <span>{{ $store.state.offre_active.machine.prix_distrib | currency }}</span> -->
						</li>
						<li>
							<label>{{ $store.state.textes.page9.machines_subtotal }}</label>
							<span class="wrapper">
								<span class="hide_price masked" @click="toggleVisibility">Hide price</span>
								<money-format
									:value="$store.state.offre_active.sous_total_machines * taux_de_change"
									:currency-code="$store.state.offre_active.devise"
									:subunits-value="false" :hide-subunits="false"
									:locale="$store.state.offre_active.langue">
								</money-format>
							</span>
						</li>
						<div class="details" v-if="details_options_machines_active.length">
							{{ details_options_machines_active.join(", ") }}
						</div>
						<li>
							<label>{{ $store.state.textes.page9.commands_subtotal }}</label>
							<span class="wrapper">
								<span class="hide_price masked" @click="toggleVisibility">Hide price</span>
								<money-format
									:value="$store.state.offre_active.sous_total_controls * taux_de_change"
									:currency-code="$store.state.offre_active.devise"
									:subunits-value="false" :hide-subunits="false"
									:locale="$store.state.offre_active.langue">
								</money-format>
							</span>
							<!-- <span>{{ $store.state.offre_active.sous_total_controls | currency }}</span> -->
						</li>
						<div class="details" v-if="details_options_controls.length">
							{{ details_options_controls.join(", ") }}
						</div>

						<li>
							<label>{{ $store.state.textes.page9.accessories_subtotal }}</label>
							<span class="wrapper">
								<span class="hide_price masked" @click="toggleVisibility">Hide price</span>
								<money-format
									:value="$store.state.offre_active.sous_total_accessoires * taux_de_change"
									:currency-code="$store.state.offre_active.devise"
									:subunits-value="false" :hide-subunits="false"
									:locale="$store.state.offre_active.langue">
								</money-format>
							</span>
							<!-- <span>{{ $store.state.offre_active.sous_total_accessoires | currency }}</span> -->
						</li>
						<div class="details" v-if="accessoires_options.length">
							{{ accessoires_options.join(", ") }}
						</div>

						<li class="total">
							<label contenteditable="true">{{
								$store.state.textes.page9.montant_total_depart }}</label>
								<span class="wrapper">
									<span class="hide_price masked" @click="toggleVisibility">Hide price</span>
									<money-format :value="$store.state.offre_active.total * taux_de_change"
										:currency-code="$store.state.offre_active.devise"
										:subunits-value="false" :hide-subunits="false"
										:locale="$store.state.offre_active.langue">
									</money-format>
								</span>
							<!-- <span>{{ $store.state.offre_active.total | currency }}</span> -->
						</li>
						<li class="extra-options">
							<div class="option" v-for="(option, index) in cost_options"
								:key="`cost_option_${index}`">
								<label>{{ option.label }}</label>
								<money-format :value="option.price"
									:currency-code="$store.state.offre_active.devise"
									:subunits-value="false" :hide-subunits="false"
									:locale="$store.state.offre_active.langue">
								</money-format>
							</div>
						</li>
						<li class="remise" contenteditable="true">
							<label v-html="$store.state.textes.page9.remise"></label>
							<span class="wrapper">
								<span class="hide_price masked" @click="toggleVisibility">Hide price</span>
								<money-format :value="total_with_options"
									:currency-code="$store.state.offre_active.devise"
									:subunits-value="false" :hide-subunits="false"
									:locale="$store.state.offre_active.langue">
								</money-format>
						</span>
							<!-- <span>{{ $store.state.offre_active.total | currency }}</span> -->
						</li>

						<div class="options">
							<div v-show="options">
								<div class="line option" v-for="(option, index) in options"
									:key="`option${index}`">
									<div class="intitule" v-html="option.intitule"></div>
									<money-format :value="parseFloat(option.prix)"
										:currency-code="$store.state.offre_active.devise"
										:subunits-value="false" :hide-subunits="false"
										:locale="$store.state.offre_active.langue">
									</money-format>
									<div class="remove_line_option" @click="removeLineOption(index)">-
									</div>
								</div>
							</div>
							<form id="new_option" @submit.prevent="add_option" class="newligne">
								<input required type="text" id="new_option_intitule"
									v-model="option_intitule" placeholder="Add an option here" />
								<input required type="number" id="new_option_prix" v-model="option_prix"
									placeholder="0.00" />
								<button type="submit"><i class="far fa-plus"></i></button>
							</form>
						</div>
					</ul>
					<div @blur="handleChange" v-if="$store.state.offre_active.page9_signature"
						contenteditable="true" data-field="page9_signature"
						v-html="$store.state.offre_active.page9_signature" />
					<div @blur="handleChange" v-else contenteditable="true" data-field="page9_signature">
						<p>{{ $store.state.textes.page9.disposition }}</p>
						<p class="yours">{{ $store.state.textes.page2.yours }}</p>
						<p class="signature">
							<strong>{{ nom_commercial | capitalize }}</strong>
							<br />
							<span v-if="nom_societe">{{ nom_societe }}</span>
							<span v-else>AMB PICOT</span>
						</p>
					</div>


				</div>
				<div class="logo-pied-de-page">
					<img src="../../assets/logofooter.png" />
				</div>
				<PiedDePage :numero="$store.state.ordre_des_pages.page9.count" />
			</div>
		</div>
	</div>
</template>
<script>
import Airtable from "airtable";
import _ from "underscore";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");
import MoneyFormat from "vue-money-format";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "page9",
	components: { MoneyFormat },
	data() {
		return {
			cout_transport: 0,
			logo: "",
			taux_de_change: 1,
			option_intitule: "",
			option_prix: "",
			details_options_machines_active: [],
			details_options_controls: [],
			accessoires_options: [],
		};
	},
	methods: {
		toggleVisibility(e) {
			console.log("coucou");
			let parent = e.target.parentElement;
			parent.classList.toggle("masked");
			console.log("coucou");
		},
		...mapActions({ add_page9_options: "add_page9_options", removeLineOption: "removeLineOption" }),
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.commit("setActiveElement", {
					field: e.currentTarget.dataset.field,
					value: e.currentTarget.innerHTML,
				});

				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
		add_option(e) {
			this.add_page9_options(e).then((res) => {
				this.option_intitule = "";
				this.option_prix = "";
			});
		},
	},
	computed: {
		nom_commercial() {
			return this.$store.state.commerciaux.filter(el => el.ID == this.$store.state.offre_active.id_commercial)[0].display_name || "Commercial";
		},
		nom_societe() {
			return this.$store.state.commerciaux.filter(el => el.ID == this.$store.state.offre_active.id_commercial)[0].custom_fields?.societe || "Société";
		},
		custom_logo() {
			return this.$store.state.commerciaux.filter(el => el.ID == this.$store.state.offre_active.id_commercial)[0].custom_fields.logo_pour_les_offres;
		},
		descriptif_options: {
			get() {
				let options_actives_machines = document.querySelectorAll(".subtotal-machines.option:not(masked)");
			},
		},
		total_with_options: {
			get() {
				let total_final = 0;
				if (this.cost_options) {
					this.cost_options.forEach((el) => {
						total_final += el.price;
					});
				}
				total_final += this.$store.state.offre_active.total * this.taux_de_change;
				return total_final;
			},
		},
		cost_options() {
			return this.$store.state.offre_active.extra_options_treated;
		},
		...mapGetters({
			options: "options",
		}),
	},
	watch: {
		total_with_options(newval, oldval) {
			let options_actives_machines = document.querySelectorAll("#page5 .subtotal-machines.option:not(.masked)");
			let options_actives_controls = document.querySelectorAll("#page6 .subtotal-commandes.puceor:not(.masked)");
			let options_actives_accessoires = document.querySelectorAll("#page6 .subtotal-accessoires.option:not(.masked)");
			if (options_actives_machines) {
				this.details_options_machines_active = [];
				options_actives_machines.forEach((el) => {
					this.details_options_machines_active.push(el.querySelector("span").innerText);
				});
			}
			if (options_actives_accessoires) {
				this.accessoires_options = [];
				options_actives_accessoires.forEach((el) => {
					this.accessoires_options.push(el.querySelector("span").innerText);
				});
			}
			if (options_actives_controls) {
				this.details_options_controls = [];
				options_actives_controls.forEach((el) => {
					this.details_options_controls.push(el.querySelector("span.content").innerText);
				});
			}
		},
		cost_options: {
			handler(val, oldVal) {
				console.log("options couteuses evolue");
			},
			deep: true,
		},
	},

	mounted() {
		const ownerID = this.$store.state.offre_active.id_commercial;
		const commerciaux = this.$store.state.commerciaux;
		const lecommercial = _.find(commerciaux, (el) => {
			return el.ID == ownerID;
		});
		if (this.$store.state.offre_active.devise != "EUR") {
			if (this.$store.state.offre_active.taux_de_change_perso) {
				this.taux_de_change = this.$store.state.offre_active.taux_de_change_perso;
			} else {
				this.taux_de_change = this.$store.state.currencies[this.$store.state.offre_active.devise];
			}
		}
	},
};
</script>

<style lang="less">
#page9 .layout {
	display: flex;
	word-break: break-word;
	.gauche {
		flex: 0 0 300px;
		.logo {
			transform: translateX(0px);
			margin-bottom: 210px;
			img {
				max-width: 220px;
				height: auto;
			}
		}
		.box {
			padding-left: 15px;
			line-height: 20px;
			margin-bottom: 50px;
			font-size: 14px;
			color: lighten(black, 40%);
			h2 {
				color: #f18b00;
				font-size: 18px;
				margin: 0;
				line-height: 20px;
				padding: 0 0 5px;
			}
			h3 {
				color: #000;
				line-height: 16px;
				margin: 0;
				padding: 0 0 3px;
				text-transform: uppercase;
				font-weight: bold;
			}
			p {
				margin: 0;
			}
			.brief {
				strong {
					color: black;
				}
			}
		}
	}
	.droite {
		flex: 1;
		padding-left: 80px;
		.recap {
			margin: 20px 0;
			padding: 0;
			.details {
				font-size: 14px;
				line-height: 18px;
				color: slategray;
				margin-bottom: 10px;
				max-width: 80%;
			}
			li {
				display: flex;
				justify-content: space-between;
				label {
					font-weight: bold;
					color: black;
					flex: 4;
					word-break: keep-all;
				
				}
				span.wrapper {
					color: #f18b00;
					flex: 1;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					text-align: right;
					gap: 10px;
					margin-bottom: 5px;
					.hide_price {
						flex: 0 0 auto;
						cursor: pointer;
						font-size: 10px;
						background: rgba(#f18b00, 0.1);
						padding: 0 3px;
						border: solid 1px rgba(#f18b00, 0.3);
						border-radius: 4px;
					}
					.money_format {
						white-space: nowrap;
						flex: 1;
					}
					&.masked {
						.money_format {
							opacity: .2;
							color: slategray;
						}
					}
				}
				&.total {
					margin-top: 30px;
					margin-bottom: 5px;
					border-bottom: solid 1px lighten(black, 90%);
					label {
					}
					span {
						font-weight: bold;
						font-size: 1.1em;
					}
					.money_format {
						white-space: nowrap;
						margin-bottom: 10px;
					}
				}
				&.transport,
				&.reception {
					font-size: 0.8em;
					margin-top: 5px;
					label {
						font-weight: 500;
					}
				}
				&.remise {
					margin-bottom: 30px;
					label {
						span {
							font-weight: normal;
							font-size: 0.8em;
							color: slategray;
						}
					}
				}
				
			}
		}
		h1 {
			font-size: 60px;
			line-height: 70px;
			color: lighten(black, 60%);
			font-weight: 300;
			margin: 140px 0 20px 0;
			display: flex;
			flex-direction: column;
			span:first-child {
				color: #f18b00;
			}
		}
		hr {
			width: 60px;
			height: 10px;
			background: #f18b00;
			border: none;
			margin: 30px 0 50px;
			transform: translateX(-30px);
		}
		.letter {
			font-size: 18px;
			line-height: 24px;
			font-weight: 300;
			padding-right: 60px;
			p {
				margin-bottom: 20px;
			}
			.date {
				margin: 0 0 40px;
			}
			.dear {
				font-weight: bold;
				color: #f18b00;
				margin-bottom: 15px;
			}
			.intro {
				margin-bottom: 50px;
			}
			.lamachine {
				font-size: 30px;
				line-height: 34px;
				font-weight: bold;
				color: #f18b00;
				margin-bottom: 0px;
				span {
					color: #000;
					display: inline-block;
					padding-right: 10px;
				}
			}
			.hope {
				color: #f18b00;
			}
			.yours {
			}
			.signature {
				text-align: right;
				padding-right: 0px;
				margin-top: 40px;
			}
		}
		#new_option {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 10px;
			#new_option_intitule {
				flex: 1;
				appearance: none;
				border: none;
				outline: none;
				font-size: 15px;
				font-weight: 500;
				color: black;
				&::placeholder {
					color: black;
					font-weight: 500;
				}
			}
			#new_option_prix {
				flex: 0 0 auto;
				appearance: none;
				border: none;
				outline: none;
				font-size: 15px;
				font-weight: 500;
				color: #f18b00;
				text-align: right;
				padding: 0;
				&::placeholder {
					color: #f18b00;
					font-weight: 500;
				}
			}
			button[type="submit"] {
				background: #f18b00;
				border: none;
				color: white;
			}
		}
		.options {
			.option {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				position: relative;
				.intitule {
					font-size: 15px;
					font-weight: 400;
					color: #000;
					padding-right: 40px;
					line-height: 20px;
					flex: 1;
				}
				.money_format {
					color: #f18b00;
					font-size: 15px;
					font-weight: 500;
					flex: 0 0 auto;
					white-space: nowrap;
				}
				.remove_line_option {
					flex: 0 0 auto;
					font-weight: bold;
					cursor: pointer;
					background: rgba(black, 0.1);
					height: 20px;
					width: 20px;
					border-radius: 50%;
					line-height: 20px;
					text-align: center;
					display: none;
					margin-left: 10px;
				}
				&:hover {
					.remove_line_option {
						display: block;
					}
				}
			}
		}
		.extra-options {
			flex-direction: column;
			margin-bottom: 10px;
			.option {
				display: flex;
				font-size: 16px;
				line-height: 16px;
				justify-content: space-between;
				margin-bottom: 5px;
				label {
					margin-bottom: 0;
				}
				.money_format {
					color: #f18b00;
					white-space: nowrap;
				}
			}
		}
	}
}
#page9.readyforexport {
	.droite {
		#new_option {
			display: none;
		}
	}
}
</style>
